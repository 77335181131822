@import "../../../../index.scss";
.ViewAssignDeviceToPlans {
  @include positionCenter;
  width: 60rem;
  border-radius: 1rem;
  background-color: #fff;
  padding: 2rem 2rem;
  min-height: 30rem;
  @include flexbox;
  @include flex-direction(column);
  .header {
    @include modelHeader;
  }
  .breakLine {
    @include modelBreakLine;
  }
  .form {
    @include flexbox;
    @include flex-wrap(wrap);
    @include justify-content(flex-start);
    margin-bottom: auto;
    margin-top: 1rem;
    position: relative;
  }
  .spinnerWrapper {
    @include positionCenter;
  }
  .inputWrapper {
    @include flex(0 0 45%);
    @include inputWrapper;
    margin: 1rem;
  }
  .btnWrapper {
    @include flexbox;
    margin-top: 1rem;
    @include justify-content(flex-end);
    margin-right: 2.5rem;
  }
  .spinnerWrapper {
    @include positionCenter;
  }
  .label {
    font-size: 1.2rem;
    margin-top: 1.3rem;
    padding-bottom: 2rem !important;
    padding-left: 1rem;
  }
  .noPlanMsg {
    @include globalInfoMsg;
    margin-left: 1rem;
  }
}
