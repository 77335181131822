@import "../../index.scss";
.leftWrapperDiv {
  @include flexbox;
  @include flex-direction(column);
  @include flex(0 0 100%);
  position: relative;
}
.topRow {
  @include flexbox;
}
.wrapperSiteAndInputs {
  @include flexbox;
  @include flex-direction(column);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 5rem;
}
.leftDiv {
  display: none;
  @include flex-direction(column);
  position: relative;
}
.open {
  margin-left: 1rem;
  @include flexbox;
}
.error {
  @include globalErrorMsg;
  visibility: hidden;
  background-color: #fff;
}
.showError {
  visibility: visible;
  padding-left: 0.5rem
}
.sitesBtn {
  @include mapButton;
  width: 10rem;
}
.closeIcon {
  margin-left: auto;
  margin-left: 0.2rem;
}
.icon {
  margin-right: 0.2rem;
  color: var(--color-primary-blue);
}
.text {
  margin-right: 0.5rem;
}
.searchBtn {
  @include mapButton;
  width: 12rem;
}
.tool {
  padding: 1rem 0rem 1rem 0rem;
  margin-right: 0.5rem;
}
.nmsTool {
  @include mapButton;
  width: 13rem;
}

.delTool {
  font-size: 1.2rem;
  font-weight: 650;
  width: 2rem;
  cursor: pointer;
  background: #ffff;
  border: none;
}
button:focus {
  outline: 0;
}
.drawBtnWrapper {
  @include flexbox;
   @include flex-direction(row);;
}

.iconSearch {
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.3rem;
  position: relative;
  top: 0.1rem;
}
.LatLngInput::placeholder ,.inputHiskySearch::placeholder {
  color: #c8c8c8;
}
.inputsWrapper {
  @include flexbox;
  background-color: #fff;
  @include justify-content(space-around);
  @include align-items(center);
  margin-top: 1rem;
  padding: 0.5rem;
  overflow: hidden;
  @include flex-wrap(wrap);
  box-shadow: var(--shadow-light);

}
.LatLngInput,
.inputHiskySearch {
  padding: 0.375rem 0.75rem;
  z-index: 900;
  font-weight: 400;
  line-height: 3;
  width: 20%;
  height: 4rem;
  font-size: 1.2rem;
  background: none rgb(255, 255, 255);
  border: 1px solid var(--color-input-border);
  border-radius: 0.25rem;
  &:focus{
    border:1px solid var(--color-input-border) ;
  }
  &:disabled{
    background-color: #e2e2e2;
    cursor: not-allowed;
  }
}
.inputHiskySearch {
  width: 40%;
}
.okBtn {
  @include mapButton;
  width: 10%;
  padding: 0 1rem;
  margin: unset;
}
