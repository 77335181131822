@import "../../../index.scss";
.Login {
  @include flexbox;
  @include justify-content(center);
  @include align-items(center);
  @include flex-wrap(wrap);
  overflow: hidden;
  .fromLoginWrapper {
    @include flex(0 0 65%);
    @include respond(phone) {
      @include positionCenter;
      width: 100%;
      @include flex(0 0 100%);
    }
  }
 
  form {
    @include flexbox;
    @include flex-direction(column);
    @include justify-content(center);
    padding: 0 2rem;
    width: 80%;
    margin-left: 4rem;
  }
  .btnSubmitControl {
    @include flexbox;
    @include justify-content(flex-end);
    @include align-items(center);
    width: 91.5%;
    margin-top: 1.4rem;
    @include respond(phone) {
      width: 100%;
    }
  }
  .logo {
    @include flexbox;
    @include align-items(center);
    @include justify-content(flex-start);
    margin-bottom: 1.5rem;
    .photo {
      width: 10rem;
      height: auto;
    }
    @include respond(phone) {
      width: 14rem;
    }
  }
  .visiblePassword {
    position: absolute;
    top: 56%;
    left: 88%;
    font-size: 1.2rem;
    cursor: pointer;
  }
  .secondHeader {
    color: var(--color-gray-dark-2);
    font-size: 2.5rem;
    font-weight: 400;
    margin-bottom: 1.5rem;
    @include respond(phone) {
      font-size: 1.5rem;
    }
  }
  .msg {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    @include respond(phone) {
      font-size: 1.2rem;
    }
  }
  .breakLine {
    width: 91.5%;
    border: 0.5px solid var(--color-border);
    @include respond(phone) {
      width: 100%;
    }
  }
  .inputGroup {
    @include flexbox;
     @include flex-direction(row);
    @include flex-wrap(wrap);
    margin-bottom: 2rem;
    width: 100%;
    position: relative;
    @include respond(phone) {
      @include flex-direction(column);
    }
  }
  .inputWrapper {
    @include flexbox;
    @include flex-direction(column);
    @include flex(0 0 45%);
    width: 100%;
    margin-top: 1rem;
    &:not(:last-child) {
      margin-right: 1.5rem;
    }
    @include respond(phone) {
     @include  flex(0 0 100%);
    }
  }
  .error {
    @include globalErrorMsg;

  }
 
  .visiblePassword {
    position: absolute;
    transform: translate(0%, -50%);
    -webkit-transform: translate(0%, -50%);
    top: 63% !important;
    right: 0rem !important;
    cursor: pointer;
    color: black;
    @include respond(phone) {
      top: 81% !important;
    }
  }
  .spinnerIcon {
    margin-left: 0.5rem;
    display: inline-block;
    color: var(--color-primary-blue);
  }
  .msgPass {
    font-size: 1.2rem;
    color: var(--color-primary-blue);
    &:hover {
      text-decoration: underline;
    }
  }
  .resetPassWrapper {
    @include flexbox;
    width: 91.5%;
    @include justify-content(flex-start);
    cursor: pointer;
    margin-top: 1rem;
  }
  .goBackIcon {
    margin-right: 0.5rem;
  }
  .resetLinkMsg {
    font-size: 1.2rem;
  }
  .disabledLink {
    cursor: not-allowed;
  }
  
}
