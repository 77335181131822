@import "../../index.scss";

.tab {
  width: 100%;
  padding: 1rem;
  font-family: inherit;
}

ul.inline {
  list-style: none;

  li {
    display: inline-block;
    margin-right: 0.2;
    padding: 1rem;
    border-bottom: .3rem solid white;
   @include transition(all 0.5s);
    font-family: inherit;
    font-size: var(--input-label-font-size);
    cursor: pointer;
    color: var(--color-input-font);
    &.selected {
      border-bottom: .3rem solid var(--color-button);
      @include respond(tab-port-big) {
        border-bottom: 1.5px solid var(--color-button);
      }
      color: var(--color-input-font);
    }
    @include respond(max-height-model) {
      padding: .8rem;
    }
  }
}
