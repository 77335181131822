@import "../../index.scss";
.DashBoarChartUsage {
  width: 100%;

  .header {
    @include flexbox;
    @include justify-content(center);
    @include align-items(center);
    margin: 1rem 0;
    margin-bottom: 2rem;
    position: relative;
    margin-left: 4%;
  }
  .title {
    font-size: 1.4rem;
    font-weight: 500;
    position: relative;
    margin-right: auto;
    color: black;
  }
  .breakLineTitle {
    position: relative;
    margin-left: 3%;
    margin-right: 2rem;
    border: 0.5px solid var(--color-border);
    margin-top: -1rem;
  }
  .icon {
    font-size: 2.2rem;
    color: var(--color-primary-blue);
    margin-right: 2rem;
    @include flexbox;
    cursor: pointer;
    margin-top: -0.5rem;
  }
  .iconNoCursor {
    cursor: default;
  }
  .btn {
    background-color: var(--color-button);
    color: #fff;
    outline: none;
    border-radius: .6rem;
    border: none;
    padding: 1rem 1rem;
    margin-right: 2.5rem;
    cursor: pointer;
  }
  .lineChartWrapper {
    position: relative;
    margin-bottom: 1rem;
    min-height: 20rem;
  }
  .totalAmountWrapper {
    @include flexbox;
    @include respond(tab-port-between) {
      font-size: 1rem;
    }
    font-size: 1.2rem;
    margin: 1rem 0;
    margin-bottom: 1rem;
    margin-left: 4rem;
    @include flex-direction(column);
    @include justify-content(flex-start);
    margin-right: auto;
  }
  .amountWrapper{
 @include flexbox;
    font-size:1.1rem;   
  }
  .topWrapper {
    @include flexbox;
  }
  .outboundString {
    margin-right: auto;
  }
  .dateDuration {
    @include respond(tab-port-between) {
      font-size: 1rem;
    }
    font-size: 1.2rem;
    @include flexbox;
  }
  .runtimeWrapper {
    margin-left: 4rem;
    @include flexbox;
    font-size: 1.2rem;
    @include flex-direction(column);
    @include align-items(flex-start)
  }
  .durationText {
    font-weight: bold;
    padding-right: 0.3rem;
  }
  .text {
    font-weight: bold;
    padding-right: 0.3rem;
  }
  .datesWrapper {
    @include flexbox;
    @include flex-direction(column);
    @include justify-content(flex-end);
    margin: 1rem 0;
    margin-right: 2.5rem;
  }
  .present {
    margin-left: 2rem;
    font-size: 1.6rem;
    color: var(--color-primary-blue);
  }
  .noDataMsg {
    @include positionCenter;
    font-size: 1.6rem;
  }
  .spinnerWrapper {
    @include positionCenter;
  }
  .toolTip {
    @include toolTip;
  }
  .icon:hover .toolTip {
    visibility: visible;
    color: var(--color-primary-blue);
  }
  .slantLine {
    border-bottom: 1px solid black;
    transform: translateY(4px) translateX(-6px) rotate(146deg);
    position: absolute;
    display: inline-block;
    width: 3.4rem;
  }
  .customLegend {
    position: absolute;
    right: 2.7rem;
    top: -1rem;
    background-color: #fff;
    @include flexbox;
  }
  .typeWrapper {
    @include flexbox;
    margin-right: 0.5rem;
    @include align-items(center);
  }
  .rectangle {
    display: inline-block;
    width: 2rem;
    height: 1rem;
    margin-right: 0.5rem;
    cursor: pointer;
  }
  .tabsWrapper {
    height: 2rem;
    margin-top: -1rem;
    margin-right: 0.5rem;
  }
}
