@import "../../index.scss";
.Model {
  position: fixed;
  z-index: 500;
  width: 100%;
  height: 100%;
  padding: 1.6rem;
  @include box-sizing;
  @include transition(all 0.3s ease-out);
}
