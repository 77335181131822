@import "../../index.scss";
.copyApiKey {
  @include flexbox;
  @include flex-direction(column);
  width: 100%;
  .copyWrapper {
    @include flexbox;
    @include justify-content(flex-start);
  }
  .titleWrapper {
    @include flexbox;
    width: 100%;
    @include justify-content(space-between);
  }
  .title {
    @include flex(0 0 66%);
    font-size: 1.8rem;
    margin-top: 1rem;
    color: black;
    text-transform: capitalize;
    @include respond(tab-port-big) {
      font-size: 1.6rem;
    }
  }
  .apiInputWrapper {
    @include flex(0 0 80%);
    @include inputWrapper;
    margin: 1rem 1rem 0rem 0rem;
    &:first-child {
      margin-top: 0rem;
    }
    &:nth-child(2) {
      margin-top: 0rem;
    }
  }
  .breakLine {
    @include modelBreakLine;
    margin-top: 1rem;
  }
  .apikeyInputs {
    @include flexbox;
    @include flex-direction(row);
  }

  .copyWrapper {
    @include flexbox;
    @include justify-content(center);
    border-radius: .6rem;
    margin-top: .8rem;
    padding: 0.7rem 1.75rem;
    background-color: var(--color-button);
    border: none;
    outline: none;
    color: #fff;
    cursor: pointer;
    font-size: 1.4rem;
    text-transform: capitalize;
    height: fit-content;
    @include respond(tab-port-big) {
      padding: 0.4rem 1.75rem;
    }
    @include hover;
    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }
  .copyIcon {
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
  }
  .iconNet {
    color: var(--color-primary-blue);
    margin-right: 0.5rem;
  }
}
