@import "../../index.scss";
.actionWrapper {
  @include flexbox;
  @include flex-direction(column);
  @include align-items(flex-end);
}
.rightActionsWrapper {
  @include flexbox;
   @include flex-direction(row);;
  @include justify-content(flex-start);
}
.nopointerevent {
  pointer-events: none;
  cursor: default;
}
.fullScreenBtn {
  @include mapButton;
  margin-right: 1rem;
  padding: 0px;
  user-select: none;
  width: 4rem;
  font-size: 1.7rem;
  background-image: url(../../assets/FullScreen.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 25px 25px;
}
.imgIcon {
  width: 1.3rem;
  height: 1.3rem;
  position: relative;
  top: 0.3rem;
  margin-right: 0.2rem;
}
.exitFullScreen {
  @include mapButton;
  margin-right: 1rem;
  margin-top: 1rem;
  width: 4rem;
  font-size: 1.7rem;
  background-image: url(../../assets/ExitFullScreen.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 25px 25px;
}
.ZoomBtn {
  @include mapButton;
  width: 9.5rem;
  background-position-x: 4px;
  background-size: 15px 15px;
 
}
.recenterBtn {
  @include mapButton;
  width: 8.5rem;
  background-image: url(../../assets/CenterMap.svg);
  background-repeat: no-repeat;
  background-position: left;
  padding-left: 15px;
  background-position-x: 4px;
  background-size: 15px 15px;

}
.showPolygonBtn {
  @include mapButton;
  left: 75px;
  top: 0px; 
  width: 10.5rem;
}
.tool {
  padding: 1rem 0rem 1rem 0rem;
  margin-right: 0.5rem;
}
.iconSat {
  width: 2rem;
  height: 2rem;
  margin-right: 0.3rem;
  position: relative;
  top: 0.4rem;
}

.customToast {
  position: unset !important;
}
.infoIcon {
  font-size: 1.3rem;
  color: White;
  margin-right: 1rem;
}
.iconZoom {
  font-size: 1.3rem;
  margin-right: 1rem;
  color: var(--color-primary-blue);
}
.closeIcon {
  margin-left: auto;
  margin-left: 0.2rem;
}
.textZoom {
  margin-right: 0.5rem;
}
