@import "../../../index.scss";
.InfoPolygon {
  @include flexbox;
  height: 33rem;
  width: 100rem;
  background-color: #fff;
  transition: width .5s;
  border-radius: 0.5rem;
  position: relative;
  z-index: 1000000000000;
}
  .info {
    padding: 1rem;
    width: 100%;
    @include flex-direction(column);
  }
  .header {
    @include modelHeader;
    @include flexbox;
    @include align-items(center);
    @include flex-direction(row);
    @include justify-content(space-between);
    margin-bottom: 1rem;
  }
  .breakLine {
    @include modelBreakLine;
  }
  .cordPoly {
    font-size: 1.2rem;
    margin-left: 1rem;
    text-transform: capitalize;
  }
  .pipe {
    display: inline-block;
    height: 2.5rem;
    width: 0.1rem;
    font-weight: bold;
    margin: 0 1.2rem;
    background-color: gray;
  }
  .iconWrapper{
    @include flexbox;
  }
  .iconClose {
    font-size: 1.5rem;
    margin-left: auto;
    margin-right: 1rem;
    cursor: pointer;
    padding:0.3rem;
  }
  .eyeIcon {
    font-size: 1.5rem;
    margin-left: auto;
    margin-right: 1rem;
    cursor: pointer;
    color:var(--color-primary-blue);
    padding:0.3rem;
  }
  .data {
    @include flexbox;
    width: 100%;
    height: calc(100% - 7rem);
    margin-top: 0.5rem;
    position: relative;
  }
  .rightSide,.rightSideHub {
    width: 70%;
    height: 100%;
    position: relative;
    overflow: auto;
    @include flexbox;
    @include flex-wrap(wrap);
    padding: 1rem;
  }
  .leftSide {
    width: 30%;
    height: 100%;
    overflow: auto;
  }
  .selectedBeam {
    color: #fff;
    background-color: var(--color-primary-blue);
  }
  .logo {
    @include positionCenter;
    width: 20rem;
  }
  .listWrapper {
    list-style-type: none;
    width: 100%;
    height: 100%;
    padding: 1rem;
  }
  .itemBeam {
    padding: 1rem;
    font-size: 1.4rem;
    @include flexbox;
    @include align-items(center);
    cursor: pointer;
    &:not(:last-child) {
      border-bottom: 0.5px solid var(--color-border);
    }
  }
  .item,.itemHub {
    @include flex(0 0 20%);
    list-style: none;
    @include flexbox;
    @include flex-direction(column);
    margin: 0.5rem 0rem;
    padding-bottom: 0.5rem;
    height: fit-content;
    @include align-items(flex-start);
    border-bottom: 1px solid var(--color-border);
  }
  .noDataMsg {
    position: absolute;
    left: 1rem;
    top: 1rem;
    font-size: 1.4rem;
  }
  .spinnerWrapper{
    @include positionCenter;
  }

.InfoPolygonHub{
  @include flexbox;
  height: 100%;
  width: 100%;
  overflow: auto;
  background-color: #fff;
  transition: width .5s;
  border-radius: 0.5rem;
  position: relative;
  z-index: 2 !important;
  top: 2rem;
  margin: 0 auto;
  max-width: 100rem;

}
.itemHub{
  @include flex(0 0 25%);
}
.rightSideHub{
  width: 100%;
}