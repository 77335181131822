@import "../../../index.scss";

.Log {
  @include flexbox;
  @include flex-direction(column);
  @include flex(0 0 32%);
  background-color: #ffff;
  border-radius: 1rem;
  padding: 1rem 1rem;
  position: relative;
  overflow: hidden;
  box-shadow: var(--shadow-light);
  .header {
    @include flexbox;
    @include justify-content(center);
    @include align-items(center);
    margin: 1rem 0;
    margin-bottom: 1rem;
  }
  .breakLineTitle {
    position: relative;
    margin-left: 2rem;
    margin-right: 2rem;
    border: 0.5px solid var(--color-border);
  }
  .title {
    font-size: 1.4rem;
    font-weight: 500;
    position: relative;
    margin-right: auto;
    color: black;
    margin-left: 2rem;
  }
  .icon {
    font-size: 2rem;
    color: var(--color-primary-blue);
    margin-right: 2rem;
    @include flexbox;
    margin-top: -0.9rem;
  }
  .tableRow {
    @include tableRow;
    &:nth-child(odd):hover {
      background-color: var(--color-border);
    }
    &:nth-child(even):hover {
      background-color: #f2f2f1;
    }
  }
  .textCell {
    @include textCell;
    @include flex(0 0 47%);
    &:first-child {
      @include flex(0 0 5%);
    }
  }

  .spinnerWrapper {
    @include positionCenter;
  }
  .tableDataWrapper {
    min-height: 38rem;
    max-height: 38rem;
    overflow: auto;
  }
  .noDataMsg {
    @include positionCenter;
    font-size: 1.6rem;
  }
  .hiskyInfo {
    cursor: pointer;
    display: inline-block;
  }
  .hiskyInfo:hover .toolTip {
    visibility: visible;
    color: var(--color-primary-blue);
    overflow: visible;
  }
  .toolTip {
    color: var(--color-primary-blue);
    @include toolTip;
  }
}
