@import "../../../index.scss";
.DashboardMarkers {
  @include flexbox;
  height: 33rem;
  width: 100rem;
  background-color: #fff;
  transition: width 0.5s;
  border-radius: 0.5rem;
  position: relative;
  z-index: 1000000000000;
  .info {
    padding: 1rem;
    width: 100%;
    @include flex-direction(column);
  }
  .header {
    @include modelHeader;
    @include flexbox;
    @include align-items(center);
    margin-bottom: 1rem;
  }
  .breakLine {
    @include modelBreakLine;
    margin: 0.5rem 0;
  }
  .spinnerIcon {
    margin-left: 0.5rem;
    display: inline-block;
    color: var(--color-primary-blue);
  }
  .spinnerWrapper {
    @include positionCenter;
    top: 30%;
    left: 45%;
  }
  .cordPoly {
    font-size: 1.2rem;
    margin-left: 1rem;
    text-transform: capitalize;
  }
  .iconClose {
    font-size: 1.5rem;
    margin-left: auto;
    margin-right: 2rem;
    cursor: pointer;
  }
  .iconNavigation {
    font-size: 1.5rem;
    margin-left: auto;
    margin-right: 2rem;
    cursor: pointer;
    color: #00bdd6;
  }
  .data {
    @include flexbox;
    width: 100%;
    height: calc(100% - 7rem);
    margin-top: 0.5rem;
    position: relative;
  }
  .rightSide {
    width: 80%;
    height: 100%;
    position: relative;
    overflow: auto;
    @include flexbox;
    @include flex-direction(column);
    padding: 1rem;
    @include justify-content(space-between);
  }
  .itemTelemetry {
    @include flex(0 0 30%);
    list-style: none;
    @include flexbox;
    @include flex-direction(column);
    margin: 0.5rem 0rem;
    padding-bottom: 0.5rem;
    height: fit-content;
    @include align-items(flex-start);
    border-bottom: 1px solid var(--color-border);
  }
  .leftSide {
    width: 20%;
    height: 100%;
    overflow: auto;
  }
  .selectedTermianl {
    color: #fff;
    background-color: var(--color-primary-blue);
  }
  .itemTeraminl {
    padding: 1rem;
    font-size: 1.4rem;
    margin: 0 0.3rem;
    position: relative;
    cursor: pointer;
    &:not(:last-child) {
      border-bottom: 0.5px solid var(--color-border);
    }
  }
  .itemTeraminl:hover .toolTip {
    visibility: visible;
    color: var(--color-primary-blue);
    left: 50%;
  }
  .logo {
    @include positionCenter;
    width: 20rem;
  }
  .item {
    @include flex(0 0 20%);
    list-style: none;
    @include flexbox;
    @include flex-direction(column);
    margin: 0.5rem 0rem;
    padding-bottom: 0.5rem;
    height: fit-content;
    @include align-items(flex-start);
    border-bottom: 1px solid var(--color-border);
  }
  .titleWrapper {
    @include flex(0 0 100%);
  }
  .title {
    font-size: 1.4rem;
    font-weight: 800;
  }
  .subTitle {
    font-size: 1.3rem;
    font-weight: 300;
  }
  .topData,
  .sectionData {
    @include flexbox;
    @include flex-wrap(wrap);
  }
  .iconCloseWrapper {
    @include flexbox;
    margin-left: auto;
  }
  .sectionData {
    margin-top: 1rem;
  }
  .iconClose:hover .toolTip {
    visibility: visible;
  }
  .toolTip {
    @include toolTip;
  }
  .statusIcon {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    font-size: 1.1rem;
  }
  .menuType {
    @include flexbox;
    font-size: 1.2rem;
    font-weight: 300;
    margin-left: 0.5rem;
  }
  .silderWrapper {
    @include flex(0 0 75%);
    @include flex-direction(column);
    margin-bottom: 1rem;
    margin-right: auto;
  }
  .inputsWrapper {
    @include flexbox;
    width: 100%;
  }
  .timerWrapper {
    @include flexbox;
     @include flex-direction(row);;
    margin: 1rem;
    @include align-items(center);
    width: 100%;
  }
  .disabled {
    cursor: not-allowed !important;
    color: var(--color-disable) !important;
  }
  .btnApply {
    background-color: var(--color-primary-blue);
    color: #fff;
    padding: 1rem;
    outline: none;
    border: none;
    cursor: pointer;
    border-radius: 0.25rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    &:disabled {
     @include disabled;
      cursor: not-allowed;
    }
    &:hover {
     @include hover
    }
  }
  .inputTime {
    border: none;
    outline: none;
    width: 4.5rem;
    font-size: 1.2rem;
    font-weight: 400;
    padding: 1rem;
    border-radius: 0.25rem;
    color: var(--color-input-font) !important;
    background-clip: padding-box;
    border: 1px solid var(--color-input-border);
    margin-left: 1rem;
  }
  .loadingWrapper {
    @include flexbox;
     @include flex-direction(row);;
  }
  .titleInnerWrapper {
    @include flexbox;
    @include justify-content(space-between);
  }
  .noGpsErr {
    color: var(--color-red-danger);
    font-size: 1.1rem;
    margin-top: 0.5rem;
  }
}
