@import "../../../index.scss";
.mainWrapper {
  @include flexbox;
  @include flex-direction(column);
  width: 36rem;
  position: relative;
  background: white;
  margin-left: 1rem;
  padding: 2rem;
  transition: width 0.25s;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  border-radius: .2rem;
  height: auto;
}

.listWrapper {
  @include flexbox;
  @include flex-direction(column);
  height: 100%;
}
.itemsWrapper {
  @include flexbox;
  overflow: auto;
  @include flex-wrap(wrap);
  &:not(:last-child) {
    margin-bottom: 1.2rem;
  }
}
.filterTitle {
  font-size: 1.5rem;
}
.mainListWrapper {
  overflow: hidden;
  @include flexbox;
  @include flex-direction(column);
  background: white;
}
.typesWrapper {
  @include flexbox;
  border-bottom: 1px solid #e7e7e7;
  @include flex-direction(column);
  width: 100%;
}
.types {
  font-size: 1.4rem;
  padding: 1rem 0;
  @include flexbox;
  @include justify-content(flex-start);
  text-transform: capitalize;
  @include align-items(center);
  cursor: pointer;
}
.details {
  @include flexbox;
  @include flex-direction(column);
  text-transform: capitalize;
}
.closeIcon {
  @include flexbox;
  @include justify-content(flex-end);
  font-size: 1.6rem;
  position: absolute;
  padding: 0.5rem;
  cursor: pointer;
  right: 1rem;
  top: 1rem;
  padding: 1rem;
}
.countWrapper {
  @include flexbox;
  font-size: 1.2rem;
  cursor: default !important;
  margin-bottom: 0.3rem;
}
