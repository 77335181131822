@import "../../../index.scss";
.SingleLinks {
    font-size: 1.6rem;
    color: #fff;
    margin-bottom: 1rem;
    width: 95%;
    margin-left: 2.5%;
    border-radius: .6rem;
  
    &:hover {
      background-color: #0aa1b5;
    }
    .link {
      padding: 0.4rem 0;
      text-decoration: none;
      text-transform: capitalize;
      color: #fff !important;
      font-size: var(--sidebar-label-font-size);
      width: 95%;
      display: inline-block;
      margin-left: 0.5rem;
    }
    .iconLink {
      margin-right: 1.5rem;
      display: inline-block;
      margin-left: 1.7rem;
      font-size: var(--sidebar-icon-font-size);
    }
    .textLink {
      margin-right: 1.5rem;
    }
  }
  .SingleLinksClose {
    text-align: center !important ;
    @include transition(all 0.5s);
    margin-bottom: 4rem;
    .linkClose {
      text-decoration: none;
      text-transform: capitalize;
      color: #fff !important;
      font-size: 1.6rem;
      transition: all 0.5s;
    }
    .iconLinkClose {
      margin-right: auto;
      display: inline-block;
      font-size: 1.8rem;
  
      color: #fff;
      &:hover {
        color: #0aa1b5;
      }
    }
    .textLinkClose {
      display: none;
    }
  }