@import "../../../index.scss";
.AccountAndUsers {
  @include flexbox;
  @include flex-direction(column);
  @include flex(0 0 32%);
  background-color: #ffff;
  border-radius: 1rem;
  padding: 1rem 1rem;
  overflow: auto;
  position: relative;
  box-shadow: var(--shadow-light);
  .header {
    @include flexbox;
    @include justify-content(center);
    @include align-items(center);
    margin: 1rem 0;
    margin-bottom: 1rem;
  }
  .title {
    font-size: 1.4rem;
    font-weight: 500;
    position: relative;
    margin-right: auto;
    color: black;
    margin-left: 2rem;
  }
  .breakLineTitle {
    position: relative;
    margin-left: 2rem;
    margin-right: 2rem;
    border: 0.5px solid var(--color-border);
  }
  .icon {
    font-size: 2rem;
    color: var(--color-primary-blue);
    margin-right: 2rem;
    @include flexbox;
    margin-top: -0.9rem;
  }

  .companyDataWrapper {
    @include flexbox;
    @include flex-direction(column);
    margin-top: 1rem;
    max-height: 40rem !important;
    height: 100%;
    overflow: auto;
    scrollbar-width: thin;
  }
  .card {
    @include flexbox;
    border-bottom: 1px solid var(--color-border);
    @include justify-content(flex-start);
    @include align-items(center);
    padding: 1rem 1rem;
    cursor: pointer;
    margin-left: 2rem;
    margin-right: 2rem;

    &:last-child {
      border-bottom: none;
    }
  }

  .imageCardWrapper {
    width: 5rem !important;
    height: 5rem !important;
    border-radius: 50%;
    border: none;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .iconAvatar {
    font-size: 4rem;
    color: var(--color-primary-blue);
    font-weight: 400;
  }
  .image {
    width: 5rem !important;
    height: 5rem !important;
    border-radius: 50%;
    background-size: cover;
  }
  .infoUser {
    margin-left: auto;
    &:first-child {
      margin-right: 0.5rem;
      margin-bottom: 1.5rem;
    }
  }
  .infoCard {
    @include flexbox;
    font-size: 1.3rem;
    @include flex-direction(column);
    margin-right: auto;
  }
  .spinnerWrapper {
    @include positionCenter;
  }
  .index {
    font-size: 1.5rem;
    margin-left: 1rem;
    font-weight: 500;
  }
  .dateRight {
    font-size: 1.3rem;
    @media (max-width: 1600px) {
      display: none;
    }
  }
  .dateBelow {
    font-size: 1.3rem;
    @media (min-width: 1600px) {
      display: none;
    }
  }
}
