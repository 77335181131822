@import "../../../index.scss";

.ChartDatas {
  @include flexbox;
  @include flex-direction(column);
  @include flex(0 0 32%);
  background-color: #ffff;
  border-radius: 1rem;
  padding: 1rem 1rem;
  position: relative;
  box-shadow: var(--shadow-light);
  .header {
    @include flexbox;
    @include justify-content(space-between);
    margin: 1rem 0;
    color: black;
  }
  .wrapperDougnut {
    position: relative;
  }
  .title {
    font-size: 1.4rem;
    font-weight: 500;
    color: black;
    margin-left: 2rem;
  }
  .imgIcon {
    width: 3rem;
  }
  .infoChart {
    @include flexbox;
    @include justify-content(center);
    @include align-items(center);
    margin-top: 1.5rem;
  }

  .titleDoughnut {
    font-size: 1.6rem;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    @include respond(tab-port-mid) {
      font-size: 1.3rem;
    }
  }
  .breakLineTitle {
    position: relative;
    margin-left: 2rem;
    margin-right: 2rem;
    border: 0.5px solid var(--color-border);
  }
  .userAmount {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    color: black;
    font-weight: 600;
  }
  .iconTitle {
    font-size: 2rem;
    color: var(--color-primary-blue);
    margin-right: 2rem;
    margin-top: -0.9rem;
  }

  .spinnerWrapper {
    @include positionCenter;
  }
  .templateColor {
    @include positionCenter;
    border: 2.5rem solid var(--color-primary-blue);
    width: 12rem;
    height: 12rem;
    z-index: 1;
    top: 9rem;
    border-radius: 50%;
  }
  
}
