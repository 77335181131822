@import "../../index.scss";

.SideBars {
  z-index: 2;
  min-height: 100vh !important;
  position: relative;
  overflow: hidden;
  background-color: #212936;
  @include transition(width 0.3s);
  box-shadow: var(--shadow-side-bar);

  .menuWrapper {
    @include flexbox;
    margin-top: 1rem;
    @include justify-content(center);
    @include align-items(center);
  }
  .imageWrapper {
    margin-left: 1.6rem;
    margin-right: auto;
    cursor: pointer;
  }
  .subTitleClose {
    border-bottom: 2px solid #fff;
    margin: 0 0.5rem;
    margin-bottom: 4rem;
    box-shadow: var(--shadow-light);
  }
  .imageWrapperClose {
    margin: auto;
    text-align: center !important;
    z-index: 3;
    background-color: var(--color-primary-blue);
    height: 6.4rem;
  }
  .imageLogo {
    width: 6rem;
  }
  .iconBar {
    font-size: 2rem;
    color: #fff;
    margin-right: 1rem;
    cursor: pointer;
  }
  .iconBarClose {
    text-align: center;
    color: #fff;
    top: 2rem;
    font-size: 2rem;
    position: relative;
    cursor: pointer;
  }
  .linkGroup {
    margin-top: 4rem;
  }
  .subTitle {
    color: #fff;
    margin-left: 5.5%;
    font-size: 1.6rem;
    margin-bottom: 1rem;
    margin-bottom: 2rem;
    text-transform: uppercase;
    &:last-child {
      margin-top: 4.5rem;
    }
  }
  .linkWrapper {
    font-size: 1.6rem;
    color: #fff;
    margin-bottom: 1rem;
    width: 95%;
    margin-left: 2.5%;
    padding: 0.4rem 0;
    border-radius: 6px;

    &:hover {
      background-color: #0aa1b5;
    }
  }
  .link {
    text-decoration: none;
    text-transform: capitalize;
    color: #fff !important;
    font-size: 1.6rem;
    width: 95%;
    display: inline-block;
    margin-left: 0.5rem;
  }
  .iconLink {
    margin-right: 1.5rem;
    display: inline-block;
    margin-left: 1.7rem;
    font-size: 1.8rem;
  }
  .iconLinkClose {
    margin-right: auto;
    display: inline-block;
    font-size: 1.8rem;

    color: #fff;
    &:hover {
      color: #0aa1b5;
    }
  }
  .textLink {
    margin-right: 1.5rem;
  }
  .linkWrapperClose {
    text-align: center !important ;
    @include transition(all 0.5s);
    margin-bottom: 4rem;
  }
  .textLinkClose {
    display: none;
  }
  .linkClose {
    text-decoration: none;
    text-transform: capitalize;
    color: #fff !important;
    font-size: 1.6rem;
    @include transition(all 0.5s);
  }
  .version {
    margin-top: auto;
    color: #fff;
    font-size: 1.3rem;
  }
  .versionWrapper {
    @include flexbox;
     @include flex-direction(row);
  }
  .iconVersion {
    margin-left: 1rem;
    color: #fff;
    font-size: 1.4rem;
  }
}
