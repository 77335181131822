@import "../../index.scss";

.DashBoards {
  @include flexbox;
  @include flex-direction(column);
  width: 100%;
  padding: 0rem 2rem;
  padding-top: 3rem;
  position: relative;
  .notification {
    position: absolute;
    right: 4.5rem;
    z-index: 100;
  }
  .topRow {
    @include flexbox;
    @include flex-direction(column);
    width: 100%;
    padding: 3rem;
    height: 55rem;
    background-color: #fff;
    border-radius: 1rem;
    margin-bottom: 3.5rem;
    box-shadow: var(--shadow-light);
  }
  .header {
    @include flexbox;
    @include justify-content(center);
    @include align-items(center);
    width: 100%;
    position: relative;
    top: -2rem;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
  }
  .title {
    font-size: 1.6rem;
    font-weight: 500;
    margin-right: auto;
    color: black;
  }

  .iconCompass {
    font-size: 2rem;
    color: var(--color-primary-blue);
    margin-top: 1rem;
  }
  .infoIcon {
    font-size: 1.3rem;
    color: White;
    margin-right: 1rem;
  }
  .breakLineTitle {
    position: relative;
    top: -24px;
    border: 0.5px solid var(--color-border);
  }
  .mapWrapper {
    position: relative !important;
    height: 100%;
  }
  .midRow {
    @include flexbox;
    @include flex-wrap(nowrap);
    @include justify-content(space-between);
    margin-bottom: 3.5rem;
  }
  .bottomRow {
    @include flexbox;
    @include flex-wrap(wrap);
    margin-bottom: 2rem;
    @include justify-content(space-between);
  }
}
.dashBoardChartWrapper {
  @include flexbox;
  @include flex-direction(column);
  @include flex(0 0 32%);
  background-color: #ffff;
  border-radius: 1rem;
  padding: 1rem 1rem;
  position: relative;
  overflow: hidden;
  box-shadow: var(--shadow-light);
}
.spinnerWrapperMap {
  @include positionCenter;
  z-index: 4;
}
