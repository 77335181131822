@import "../../index.scss";

.pageNotFounds {
  min-width: 100vw;
  min-height: 100vh;
  @include flexbox;
  @include align-items(center);
  @include justify-content(center);
}
.container {
  margin-top: -10rem;
  text-align: center;
  @include flexbox;
  @include flex-direction(column);
}
.header {
  font-family: inherit;
  color: var(--color-sub-title);
  font-size: 3rem;
}
.icon {
  color: var(--color-sub-title);
  font-size: 4rem;
}
.btnWrapper {
  margin: 1rem;
}
