@import "../../../index.scss";
.drawingContent {
  color: var(--color-button);
  background-image: url(../../../assets/Calculator.svg);
  background-repeat: no-repeat;
  //background-position: left;
  padding-left: 2.1rem;
  background-position-y: 0.5rem;
  background-position-x: 0rem;
  background-size: 2rem 2rem;
  padding-top: 0.8rem;
  font-size: 1.3rem;
  width: 100%;
  height: 5rem;
  overflow: visible;
}
.mainWrapper {
  position: relative;
  width:16rem;
  height: 8rem;
  margin-top: 1rem;
}
.terminalsContent {
  color: var(--color-button);
}
.count {
  color:#000;
  font-weight: 500;
}
.spinnerWrapper { 
  padding-left: 4rem;
  font-size: 1.5rem;
  color: var(--color-primary-blue);
  margin-left: 3rem;
 
}
.terminalInfo {
  padding-left:0.3rem;
  font-size: 1.3rem;
}
.visible {
  visibility: visible;
}

