@import "../../../index.scss";

.SearchAutocompletes {
  @include flexbox;
   justify-items:center;
  @include align-items(center);
  position: relative !important;
  height: 2.5rem;
  width: 15.1rem;
  margin-left: 2rem;
  cursor: pointer;
  .input {
    font-family: inherit;
    font-size: 1.6rem;
    background-color: #26c7dc;
    border: none;
    color: #fff;
    border-radius: 0.6rem;
    outline: none;
    font-size: 1.4rem;
    padding: 1rem 2rem;
    padding-left: 4rem;
    &::-webkit-input-placeholder {
      font-weight: 100;
      font-size: 1.3rem;
      color: #fff;
      padding-left: 2.1rem;
      text-transform: capitalize;
    }
    &input[type="search"] {
      -webkit-appearance: textfield;
    }
  }
  .btn {
    border: none;
    background-color: #26c7dc;
    position: absolute;
    left: 2rem;
    .icon {
      font-size: 1.6rem;
      outline: none;
      border: none;
      color: #fff;
    }
  }
  .tabWrapper {
    @include flexbox;
    @include justify-content(flex-start);
    margin: 1rem 1rem;
  }

  .icon {
    font-size: 1.3rem;
    margin-right: 0.2rem;
    padding-right: 0.2rem;
    color: var(--color-primary-blue);
  }
  .resultSearch {
    position: absolute;
    width: 50vw; //40vw
    box-shadow: var(--shadow-light);
    top: 4rem;
    padding: 1rem 1rem;
    border-radius: 0.6rem;
    background-color: #fff;
    z-index: 700;
    @include flexbox;
    @include justify-content(flex-start);
    @include flex-direction(column);
    @include respond(big-tab-port) {
      width: 61vw;
    }
  }
  .tableContent {
    @include flexbox;
     @include flex-direction(row);
    position: relative;
    margin-top: 2rem;
  }
  .breakline {
    border: 0.5px solid var(--color-border);
  }
  .tablewrapper {
    @include flex(0 0 100%);
    @include flexbox;
    min-height: 40vh;
    max-height: 40vh;
    @include flex-direction(column);
    border-radius: 0.8rem;
    position: relative;
    background-color: #ffffff !important;
  }
  .tableRow {
    @include flexbox;
    flex-grow: 0;
    -webkit-flex-grow: 0;
    @include justify-content(flex-start);
    position: relative !important;
    width: 100%;
    padding: 1rem 1rem;
    font-size: var(--input-label-font-size);
    cursor: pointer;
    list-style: none;
  }
  .header {
    text-transform: capitalize;
  }
  .textCell {
    @include textCell;
    @include flex(0 0 20%);
  }

  .textTabHeader {
    @include textCell;
    @include flex(0 0 16%);
    @include flexbox;
    position: relative;
    @include flex-direction(column);
    @include justify-content(flex-start);

    &:not(:last-child) {
      margin-right: 0.2rem;
    }
  }

  .mFadeOut {
    visibility: hidden;
    opacity: 0;
    @include transition(visibility 0s linear 300ms opacity 800ms);
    color: var(--color-primary);
    text-transform: capitalize;
    text-decoration: none;
    font-size: 1.4rem;
    letter-spacing: 0.15em;
    display: inline-block;
    font-family: inherit;
    margin-top: 0.5rem;
    height: 1px;
    cursor: pointer;
    position: relative;
    width: 100%;
  }
  .titleHeaderTable {
    font-size: 1.3rem;
    margin-left: 0.2rem;
    color: var(--color-primary-blue);
  }
  .mFadeIn {
    visibility: visible;
    opacity: 1;
    @include transition(visibility 0s linear 0s opacity 800ms);
    text-transform: capitalize;
    text-decoration: none;
    letter-spacing: 0.15em;
    display: inline-block;
    font-family: inherit;
    margin-top: 0.7rem;
    height: 1px;
    cursor: pointer;
    position: relative;
    transition-duration: 1s;
    background-color: var(--color-primary-blue);
    width: 90%;
  }

  .dataWrapper {
    @include flexbox;
    @include flex-wrap(wrap);
    padding: 0rem 1.5rem 1rem 1.5rem;
    overflow: auto;
  }
  .dataColumnWrapper {
    @include flexbox;
    @include flex-direction(column);
    width: 100%;
    font-size: 1.3rem;
    cursor: pointer;
    list-style: none;
    padding-left: 1rem;
  }
  .dataCell {
    @include flexbox;
    margin-right: auto;
    font-size: 1.3rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    // text-transform: capitalize;
    padding: 0.5rem 0.2rem;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid var(--color-border);
  }
  .indexCell {
    @include flex(0 0 2%);
  }
  .indexCell:after {
    content: ".";
    margin: 0.3rem;
  }
  .spinnerWrapper {
    @include positionCenter;
  }
  .subTitle {
    margin-bottom: 0.5rem;
    text-transform: capitalize;
    font-size: 1.4rem;
  }
  .iconPlus {
    @include flexbox;
    @include justify-content(flex-end);
    @include align-items(center);
    margin-right: 0.2rem;
    font-size: 1.3rem;
    color: var(--color-primary-blue);
  }
  .plusCursor {
    cursor: pointer;
    color: var(--color-primary-blue);
  }
  .plusnoCursor {
    cursor: not-allowed;
    color: #bdbdbd;
  }
  .plusLabel {
    margin-right: 0.5rem;
  }

  .innerInfo {
    @include flexbox;
    @include flex-direction(column);
    @include align-items(flex-start);
    font-size: var(--input-label-font-size);
  }
  .label {
    width: initial;
    font-weight: bold;
    text-transform: capitalize;
    margin-right: 1rem;
  }
}
