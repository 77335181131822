@import "./mixin.scss";
:root {
  --color-red-danger: #ff1a1a;
  --color-primary-blue: #00bdd6;
  --color-border: #f2f2f6;
  --color-input-font: #495057;
  --color-sub-title: #abaaaa;
  --color-button: #2196f3;
  --color-disable: #bdbdbd;
  --color-input-border: #ced4da;
  --color-background-layout: #f2f2f2;
  --color-terminal-online:rgb(76 175 80); //chnage in constants
  --shadow-light: 0px 0px 16px -2px rgba(196, 196, 196, 0.8);
  --shadow-side-bar: 0px 5px 8px rgba(0, 0, 0, 0.3);
  --shadow-header: 0px 2px 10px 0px rgba(0, 0, 0, 0.3);
  --input-label-font-size: 1.3rem;
  --input-tableData-font-size: 1.3rem;
  --input-font-weight: 600;
  --sidebar-label-font-size: 1.6rem;
  --sidebar-icon-font-size: 1.8rem;
}

////////////////////1200px 1200/16px (example) this is how you calculate///////////////#00a0dc
$bp-largest: 75em;
$bp-large: 78.75em;
$bp-medium: 56.25em;
$bp-small: 37.5em;
$bp-smallest: 31.25;
$phone: 40em;
@media only screen and (max-width: 87.5em) {
  :root {
    --sidebar-label-font-size: 1.4rem;
    --sidebar-icon-font-size: 1.6rem;
    --input-label-font-size: 1.2rem;
    --input-tableData-font-size: 1.2rem;
  }
}

input:focus,
textarea:focus,
select:focus,
option,
option:focus,
option:hover {
  border: 1px solid #4c9aff;
  outline: none;
}

@mixin respond-phone {
  @media (max-width: 600px) {
    @content;
  }
}

@mixin respond($breakpoint) {
  @if $breakpoint == tab-port-between {
    @media (min-width: 75.06em) and (max-width: 84.31em) {
      @content;
    }
  }
  @if $breakpoint == phone {
    @media (max-width: $bp-medium) {
      @content;
    }
  }

  @if $breakpoint == bg-port {
    @media only screen and (max-width: 112.5em) {
      @content;
    }
  }
  @if $breakpoint == tab-port {
    @media only screen and (max-width: 75em) {
      @content;
    }
  }
  @if $breakpoint == tab-port-mid {
    @media only screen and (max-width: 81.25em) {
      @content;
    }
  }
  @if $breakpoint == tab-port-medium-min {
    @media only screen and (min-width: 81.25em) {
      @content;
    }
  }
  @if $breakpoint == big-tab-port {
    @media only screen and (max-width: 105.5em) {
      @content;
    }
  }
  @if $breakpoint == tab-port-big {
    @media only screen and (max-width: 87em) {
      @content;
    }
  }
  @if $breakpoint == small-phone {
    @media only screen and (max-width: 37.5em) {
      @content;
    }
  }
  @if $breakpoint == phone {
    @media only screen and (max-width: 40em) {
      @content;
    }
  }
  @if $breakpoint == smallest-phone {
    @media only screen and (max-width: 31.25em) {
      @content;
    }
  }
  @if $breakpoint == min-height-model {
    @media only screen and (min-height: 49.75em) {
      @content;
    }
  }
  @if $breakpoint == max-height-model {
    @media only screen and (max-height: 49.75em) {
      @content;
    }
  }
  @if $breakpoint == medium-height-model {
    @media only screen and (max-height: 45.93em) {
      @content;
    }
  }
  @if $breakpoint == medium-height {
    @media only screen and (max-height: 93.75em) {
      @content;
    }
  }
  @if $breakpoint ==edit-account {
    @media only screen and (max-height: 46.85em) {
      @content;
    }
  }
  @if $breakpoint == medium-height-850 {
    @media only screen and (max-height: 53.125em) {
      @content;
    }
  }
  @if $breakpoint ==table-height {
    @media only screen and (min-height: 800px) {
      @content;
    }
  }
  @if $breakpoint ==spec {
    @media only screen and (max-width: 1800px) {
      @content;
    }
  }
}

* {
  margin: 0;
  padding: 0;
}
*,
::after,
::before {
  box-sizing: inherit;
  -webkit-box-sizing: inherit;
}
html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 62.5%; //1rem=10px, 10px/16px=62.25
  @include respond(tab-port-big) {
    font-size: 50%;
  }
}
body {
  font-family: "Poppins", sans-serif;
  line-height: 1.6;
  font-weight: 400;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
}
.noScrollBody {
  overflow: hidden;
}
.rdtPicker td.rdtActive:hover {
  border-radius: 50%;
}
td.rdtYear:hover {
  border-radius: 50%;
}
td.rdtMonth:hover {
  border-radius: 50%;
}
td.rdtDay:hover {
  border-radius: 50%;
}
td.rdtDay {
  height: 4rem !important;
}
.rdtTime {
  margin-top: 1.5rem;
}
.rdtPicker td.rdtActive {
  background-color: #00bdd6 !important;
  border-radius: 50%;
}
td.rdtMonth {
  height: 7rem !important;
}
td.rdtYear {
  height: 7rem !important;
}
.rdtToday:before {
  border-bottom: 7px solid #00bdd6 !important;
}
//rdtcounters
.rdtCounter {
  margin: 1rem;
}

.rdtBtn {
  color: #00bdd6;
  font-size: 1.2rem;
}
.rdtSwitch {
  font-size: 1.3rem;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  scrollbar-width: thin;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c4c4c4;
}
::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  position: absolute;
  right: 0;
}

/** Toastify **/
.Toastify__toast-container {
  position: unset !important;
  width: 250px !important;
  padding: unset;
  margin-right: 0.5rem;
}
.Toastify__toast {
  min-height: 35px !important;
}
.Toastify__toast-body {
  font-size: 1.2rem;
  //padding: 1rem;
}
.Toastify__toast--info {
  background: var(--color-primary-blue) !important;
  cursor: default !important;
}

.d2h-code-side-linenumber {
  position: unset !important;
}
.gm-style {
  z-index: 1 !important;
  position: relative;
}
// .gm-ui-hover-effect {
//   margin-right: 2rem !important;
//   margin-top: 1.5rem !important;
// }

.d2h-file-stats {
  position: absolute !important;
  top: 13.5rem !important;
  right: 9.7rem !important;
  @include respond(spec) {
    right: 4rem !important;
    top: 13rem !important;
  }
}

.d2h-file-header,
.d2h-file-name,
.d2h-file-list-title,
.d2h-icon.d2h-moved {
  display: none !important;
}
.d2h-file-list-wrapper {
  margin-bottom: unset !important;
}
.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-listLTR::marker{
  color:gray;
}
