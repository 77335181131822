@import "../../../index.scss";
.Galleries {
  background-image: url("../../../assets/kdxwgvpticolffjskjk9.jpg");
  height: 100vh;
  background-size: cover;
  position: relative;
  @include flex(0 0 35%);
  margin-right: auto;
  position: relative;
  animation: slide 30s infinite;
  @include respond(phone) {
    display: none;
  }
  @keyframes slide {
    25% {
      background-image: url("../../../assets/hcdtn04dz4qe8abzykbo.jpg");
    }
    50% {
      background-image: url("../../../assets/kdxwgvpticolffjskjk9.jpg");
    }

    75% {
      background-image: url("../../../assets/kseu3jrkuaqf6u3dnobz.jpg");
    }
    100% {
      background-image: url("../../../assets/hcdtn04dz4qe8abzykbo.jpg");
    }
  }

  .infoGallery {
    @include positionCenter;
    color: #fff;
    // text-align: center;

    .title {
      font-size: 2.5rem;
      margin-bottom: 1.3rem;
      font-weight: 500;
      text-transform: capitalize;
    }
    .paragraph {
      font-size: 1.5rem;
      font-weight: 300;
      line-height: 2;
    }
  }
}
