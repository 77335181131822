@import "../../../index.scss";
.ResetPasswords {
@include flexbox;
  @include justify-content(center);
  @include align-items(center);
  position: relative;
  @include flex-wrap(wrap);
  overflow: hidden;
  .resetPasswordWrapper {
   @include flex(0 0 65%);
    position: relative;
  }
  form {
    @include flexbox;
    @include flex-direction(column);
    @include justify-content(center);
    padding: 0 2rem;
    width: 80%;
    margin-left: 4rem;
  }
  .btnSubmitControl {
    @include flexbox;
    @include justify-content(flex-end);
    @include align-items(center);
    width: 91.5%;
    margin-top: 1.4rem;
  }
  .logo {
    @include flexbox;
    @include align-items(center);
   @include  justify-content(flex-start);
    margin-bottom: 1.5rem;
    .photo {
      width: 10rem;
      height: auto;
    }
  }

  .secondHeader {
    color: var(--color-gray-dark-2);
    font-size: 2.5rem;
    font-weight: 400;
    margin-bottom: 1.5rem;
  }
  .msg {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  .breakLine {
    width: 91.5%;
    border: 0.5px solid var(--color-border);
  }
  .inputGroup {
    @include flexbox;
     @include flex-direction(row);
    @include flex-wrap(wrap);
    margin-bottom: 2rem;
    width: 100%;
    position: relative;
  }
  .inputWrapper {
    @include flexbox;
   @include  flex-direction(column);
    position: relative;
    @include flex(0 0 45%);
    width: 100%;
    margin-top: 1rem;
    &:not(:last-child) {
      margin-right: 1.5rem;
    }
  }
  .error {
    @include globalErrorMsg;
    font-size: 1.2rem;
  }

  .visiblePassword {
    position: absolute;
    transform: translate(0%, -50%);
    top: 60% !important;
    right: 2rem !important;
    cursor: pointer;
    color: black;
  }
  .spinnerIcon {
    margin-left: 0.5rem;
    display: inline-block;
    color: var(--color-primary-blue);
  }
  .msgPass {
    font-size: 1.2rem;
  }
  .resetPassWrapper {
    @include flexbox;
    width: 91.5%;
    @include justify-content(flex-start);
    cursor: pointer;
  }
  .goBackIcon {
    margin-right: 0.5rem;
  }
}
