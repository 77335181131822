@import "../../index.scss";

.Headers {
  background-color: var(--color-primary-blue);
  height: 6.4rem;
  @include flexbox;
  @include align-items(center);
  @include justify-content(space-between);
  width: 100%;
  z-index: 3;
  box-shadow: 6px 4px 8px rgba(0, 0, 0, 0.15);
  position: relative;

  .logo {
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    background-color: var(--color-primary-blue);
    @include positionCenter;
    .imgLogo {
      width: 7.3rem;
      height: auto;
    }
  }
  .showMenu {
    font-size: 2rem;
    color: #fff;
    position: relative;
    right: 8rem;
  }

  .linkLogo {
    text-decoration: none;
    color: black;
    &:not(:last-child) {
      margin-bottom: 0.7rem;
    }
  }
  .myAccount {
    text-decoration: none;
    color: black;
    cursor: pointer;
    &:not(:last-child) {
      margin-bottom: 0.7rem;
    }
  }
  .Search {
    @include flexbox;
    justify-items: center;
    @include align-items(center);
    position: relative !important;
    height: 2.5rem;
    width: 15.1rem;
    margin-left: 3rem;
    cursor: pointer;
    .input {
      font-family: inherit;
      font-size: 1.6rem;
      background-color: #26c7dc;
      border: none;
      color: #fff;
      border-radius: 0.6rem;
      outline: none;
      margin-left: 1rem;
      font-size: 1.4rem;
      padding: 1rem 2rem;
      padding-left: 4rem;
      &::-webkit-input-placeholder {
        font-weight: 100;
        font-size: 1.3rem;
        color: #fff;
        padding-left: 2.1rem;
        text-transform: capitalize;
      }
    }
    .btn {
      border: none;
      background-color: #26c7dc;
      position: absolute;
      left: 2rem;
      .icon {
        font-size: 1.6rem;
        outline: none;
        border: none;
        color: #fff;
      }
    }
  }
  .titleAvatar {
    font-size: 1.2rem;
    color: #fff;
  }

  .searchItem {
    padding: 1rem 0;
    padding-left: 1rem;
    cursor: pointer;
    &:not(:last-child) {
      border-bottom: 1px solid #00000017;
    }
  }
  .dropdownMenu {
    @include flexbox;
    @include flex-direction(column);
    @include align-items(center);
    margin-right: 0.6rem;
  }
  .dropdownMenu ul {
    list-style: none;
    margin: 0;
    color: #ababab;
    padding-left: 0;
    @include flexbox;
    @include flex-direction(column);
  }
  .dropdownMenu li {
    display: block;
    margin: 0 2px 0 0;
    padding: 1.2rem;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
  }
  .dropdownMenu li ul li {
    text-decoration: none;
    display: block;
    cursor: pointer;
    font-size: 1.4rem;
    &:first-child {
      margin-top: 1rem;
    }
  }
  .breakLine {
    width: 100%;
    border: 0.5px solid var(--color-border);
  }
  .dropdownMenu ul li ul {
    background-color: #fff;
    visibility: hidden;
    display: block;
    width: auto;
    min-width: 20rem;
    padding: 1rem 1rem;
    right: 0px;
    margin: auto;
    border-radius: 10px;
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.26);
    color: #4f4f4f;
    position: absolute;
    @include transition(visibility 0.1s ease-in);
    z-index: 3;
    top: 69px;
  }
  .dropdownMenu ul li:hover > ul,
  .dropdownMenu li ul:hover {
    visibility: visible;
  }
  .avatarInfoWrapper {
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    margin-right: 2rem;
    height: 100%;
    cursor: pointer;
  }
  .arrowIcon {
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
    position: relative;
    top: 0.5rem;
  }
  .toolTip {
    @include toolTip;
  }
  .welcomeTitleWrapper {
    @include flexbox;
    text-transform: capitalize;
    @include flex-direction(column);
    @include align-items(flex-start);
    cursor: pointer;
  }
  .roleTitle {
    color: #fff;
    text-transform: none;
  }
  .iconAvatar {
    font-size: 4rem;
    color: #fff;
    font-weight: 400;
    margin-right: 1rem;
    color: var(--color-primary-blue);
  }
  .imgAvatar {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 1rem;
  }
  .infoAvatar {
    @include flexbox;
    margin-left: 1rem;
    @include flex-direction(column);
    @include justify-content(center);
    margin-right: 1rem;
    font-size: 1.3rem;
    @include align-items(center);
  }
  .avatarInfoWrapper:hover .toolTip {
    visibility: visible;
    color: var(--color-primary-blue);
    top: 90%;
    text-transform: none;
  }
  .infoUser {
    @include flexbox;
    @include flex-direction(column);
  }
  .generalInfo {
    @include flexbox;
    @include align-items(center);
    @include justify-content(flex-start);
    text-transform: capitalize;
  }
  .titleWelcome {
    color: #fff;
    margin-right: 1rem;
    font-size: 1.3rem;
    font-weight: 300;
    text-transform: capitalize;
    cursor: pointer;
  }
  .actionLinkWrapper {
    @include flexbox;
    margin-top: 1rem;
    font-size: 1.3rem;
    margin-left: 0.6rem;
    @include flex-direction(column);
    text-transform: capitalize;
    @include justify-content(flex-start);
  }
  .item {
    cursor: pointer;
  }
}
