@import "../../index.scss";
.searchByTypes {
  @include justify-content(flex-start);
  @include flexbox;
  @include flex-direction(column);
}
.titleSearch {
  font-size: var(--input-label-font-size);
  padding-bottom: 0.8rem;
}
.error {
  @include globalErrorMsg;
}
.searchWrapper {
  @include flexbox;
   @include flex-direction(row);
  .input {
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    line-height: 3;
    font-size: 1.2rem;
    color: var(--color-input-font) !important;
    background-clip: padding-box;
    border: 1px solid var(--color-input-border);
    border-radius: 0.25rem;
    @include flex(0 0 85%);
    &::-webkit-input-placeholder {
      text-transform: capitalize;
      font-size: 1.2rem;
    }
    @include respond(tab-port-big) {
      line-height: 2.5;
    }
  }
  .iconSearch {
    @include flexbox;
    font-size: 1.6rem;
    background: var(--color-primary-blue);
    color: #fff;
    cursor: pointer;
    @include justify-content(center);
    @include align-items(center);
    overflow: hidden;
    @include flex(0 0 15%);
  }
  .clear {
    @include flexbox;
    @include align-items(center);
    cursor: pointer;
    font-size: 1.6rem;
    width: 2rem;
    margin-left: -2rem;
    color: var(--color-sub-title);
    z-index: 2;
  }
}
