@import "../../index.scss";
.Signinas {
  background-color: #fff;
  @include positionCenter;
  top: unset;
  width: 50rem;
  height: 30rem;
  border-radius: 1rem;
  padding: 2rem;
}
.headerIcon {
  font-size: 1.2rem;
  color: var(--color-primary-blue);
}
.header {
  @include modelHeader;
  text-transform: unset;
}
.breakLine {
  @include modelBreakLine;
  margin-bottom: 1.5rem;
}
.wrapper {
  @include flexbox;
  @include flex-direction(column);
  @include justify-content(center);
  padding: 0 2rem;
}
.title {
  font-size: 2rem;
  color: var(--color-input-font);
  margin: 1rem;
}
.inputWrapper {
  @include flexbox;
  @include flex-direction(column);
  @include flex(0 0 45%);
  width: 100%;
  margin-top: 1rem;
  &:not(:last-child) {
    margin-right: 1.5rem;
  }
  @include respond(phone) {
    @include flex(0 0 100%);
  }
}

.btnWrapper {
  @include flexbox;
  @include justify-content(flex-end);
}
.linkWrapper {
  @include flexbox;
  @include justify-content(flex-start);
  cursor: pointer;
  margin-top: 0.5rem;
  color: var(--color-primary-blue);
}
.msgPass {
  font-size: 1.2rem;
  color: var(--color-primary-blue);
  &:hover {
    text-decoration: underline;
  }
}
.linkMsg {
  font-size: 1.2rem;
}
.errMsg {
  font-size: 1.2rem;
  color: red;
}
.closeIcon {
  display: inline-block;
  @include positionCenter;
  top: 7%;
  right: 0% !important;
  cursor: pointer;
  font-size: 1.2rem;
  padding: 0.5rem;
  color: black;
}
.toolTip {
  @include toolTip;
}
.closeIcon:hover .toolTip {
  visibility: visible;
  color: var(--color-primary-blue);
}
.loadingIcon {
  font-size: 1.5rem;
  color: var(--color-primary-blue);
  margin-left: 0.8rem;
}
.disabledLink {
  cursor: not-allowed;
}
