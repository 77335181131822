@import "../../../index.scss";
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}
.custommarker {
  position: absolute;
}
.bounce {
  animation: bounce 2s 2;
}
.colorIcon {
  color: var(--color-primary-blue);
  cursor: pointer;
  position: relative;
  top: -1.5rem;
  right: 0.8rem;
  z-index: 1;
  font-size: 2rem;
}

.pin {
  position: relative;
  height: 3rem;
  top: -2.8rem;
  right: 0.5rem;
  cursor: pointer;
  z-index: 1;
}
.infoWindow {
  position: absolute;
  box-sizing: border-box;
  overflow: hidden;
  top: 1px;
  left: 7px;
  -webkit-transform: translate(-50%, -100%);
  -ms-transform: translate(-50%, -100%);
  transform: translate(-50%, -100%);
  background-color: white;
  border-radius: 0.8rem;
  padding: 1.2rem;
  z-index: 4;
  box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
}
.arrow {
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0) 51%,
    rgba(255, 255, 255, 0) 100%
  );
  box-shadow: -2px 2px 2px 0 rgba(178, 178, 178, 0.4);
  content: "";
  height: 1.5rem;
  left: 7px;
  position: absolute;
  top: 0;
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  -ms-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
  width: 1.5rem;
  z-index: 10;
}
.closebtn {
  background: none;
  display: block;
  border: 0px;
  margin: 0px;
  padding: 0px;
  position: absolute;
  cursor: pointer;
  user-select: none;
  top: 0px;
  right: -6px;
  width: 3rem;
  height: 3rem;
}
.icon {
  display: inline-flex;
  margin-right: 1rem;
  font-size: 1.2rem;
  color: black;
}
.ployIcon {
  display: inline-flex;
  margin-right: 1rem;
  font-size: 1.2rem;
  color: var(--color-primary-blue);
  cursor: pointer;
  padding: 0.5rem;
}
.toolTip {
  @include toolTip;
  color: var(--color-primary-blue);
  margin-left: 1.5rem;
  left: 11%;
}
.ployIcon:hover .toolTip {
  visibility: visible;
}

.siteMarkerWrapper {
  position: relative;
  //display: inline-block;
  cursor: pointer;
  margin-top: -4.5rem;
  margin-left: -2rem;
}
.sitesCount {
  font-size: 1.8rem;
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  text-align: center;
  top: 18%; /* Adjust this value to move the positioned div up and down */
  color: #fff;
  //width: 60%; /* Set the width of the positioned div */
}
.siteMarker {
  width: 4.5rem;
  height: 4.5rem;
  cursor: pointer;
}
