@import "../../../index.scss";
.leftWrapperDiv {
  @include flexbox;
  @include flex-direction(column);
  @include flex(0 0 100%);
  position: relative;
}
.mainWrapper {
  @include flexbox;
  @include flex-direction(column);
  position: relative;
}
.LatLngInput::placeholder {
  color: #c8c8c8;
}
.inputsWrapper {
  @include flexbox;
  margin-left: 1rem;
}
.LatLngInput {
  padding: 0.375rem 0.75rem;
  z-index: 900;
  margin-top: 1rem;
  font-weight: 400;
  line-height: 3;
  width: 8.5rem;
  height: 4rem;
  font-size: 1.2rem;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  background: none rgb(255, 255, 255);
  border: 1px solid var(--color-input-border);
  border-radius: 0.25rem;
  margin-right: 1rem;
}
.error {
  @include globalErrorMsg;
  visibility: hidden;
}
.showError {
  visibility: visible;
  width: 13rem;
  margin-left: 1rem;
  padding: 1rem;
  margin-top: 0.5rem;
  background-color: #fff;
}
.btn {
  @include mapButton;
  width: 6rem;
  padding: 0 1rem;  
  margin-right: 5.9rem;
  margin-left: unset;
}
