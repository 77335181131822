@import "../../../../index.scss";
.HubMoreInfos {
  @include positionCenter;
  width: 75rem;
  border-radius: 1rem;
  background-color: #fff;
  padding: 2rem 2rem;
  min-height: 30rem;
  @include flexbox;
  max-height: 95%;
  @include flex-direction(column);
  .header {
    @include modelHeader;
  }
  .breakLine {
    @include modelBreakLine;
  }
  .contentWrapper {
    overflow-y: auto;
  }
  .form {
    @include flexbox;
    @include flex-wrap(wrap);
    @include justify-content(space-between);
    margin-bottom: 1.8rem;
    margin-top: 1rem;
    position: relative;
  }
  .spinnerWrapper {
    @include positionCenter;
  }
  .inputWrapper {
    @include flex(0 0 45%);
    @include inputWrapper;
    margin: 1rem;
    overflow-x: hidden;
  }
  .btnWrapper {
    @include flexbox;
    @include justify-content(flex-end);
    margin-top: 3rem;
    margin-right: 1rem;
  }
  .spinnerWrapper {
    @include positionCenter;
  }
  .textAreaWrapper {
    @include flexbox;
    @include flex-wrap(wrap);
    @include justify-content(flex-start);
    position: relative;
  }
  .textArea {
    @include flex(0 0 96%);
    height: 10rem;
    margin-top: 1rem;
    margin-left: 1rem;
    font-family: inherit;
    font-size: 1.2rem;
    color: inherit;
    border: 1px solid var(--color-input-border);
    padding: 1rem;
  }
  .labelText {
    margin-left: 1rem;
    font-family: inherit;
    font-size: 1.6rem;
    color: inherit;
  }
  .error {
    margin-left: 1rem;
    margin-top: 0.3rem;
    font-family: inherit;
    @include globalErrorMsg;
  }
  .label {
    font-size: 1.2rem;
    padding-left: 1rem;
  }
  .subTitle {
    font-size: 1.6rem;
    margin: 1rem 0 1rem 1rem;
    font-weight: 500;
    text-transform: capitalize;
  }
  .checkboxWrapper {
    @include flex(0 0 45%);
    @include inputWrapper;
    margin-top: 4.5rem;
    margin-left: 1.3rem;
    margin-bottom: 1rem;
    padding-bottom: -3rem;
  }
}
.destinationWrapper {
  @include flex(0 0 100%);
  @include flexbox;
  position: relative;
  @include flex-direction(column);
  @include justify-content(space-between);
  padding: 1.5rem 1.5rem 0rem 1.5rem;
}
.destinationTitle {
  font-size: 1.7rem;
  color: black;
  text-transform: capitalize;
}
.iconNet {
  color: var(--color-primary-blue);
  margin-right: 0.5rem;
}
.destinationBreakLine {
  @include modelBreakLine;
  margin: 1rem 0;
  width: 100%;
  position: relative;
}
.iconSat {
  width: 2.1rem;
  height: 2.1rem;
  position: relative;
  top: 0.4rem;
}
