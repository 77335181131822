@import "../../../../index.scss";

.ViewSite {
  background-color: #fff;
  @include positionCenter;
  padding: 2rem 2rem;
  height: 90vh;
  margin: 1rem 1rem;
  width: 40vw;
  border-radius: 1rem;
  .header {
    @include modelHeader;
  }
  .breakLine {
    @include modelBreakLine;
  }
  .formWrapper {
    @include flexbox;
    @include flex-direction(column);
    @include justify-content(center);
    padding: 2rem 2rem;
  }
  .form {
    @include flex-wrap(wrap);
    @include flexbox;
    @include justify-content(space-between);
    overflow: auto;
  }
  .inputWrapper {
    @include flex(0 0 45%);
    @include inputWrapper;
  }
  .addressWrapper {
    @include flex(0 0 100%);
    @include inputWrapper;
  }
  .btnWrapper {
    @include flexbox;
    @include justify-content(flex-end);
    margin-top: 1.5rem
  }
  .spinnerWrapper {
    @include positionCenter;
  }
  .open {
    @include slideTransitionOpen;
  }
  .table {
    margin-top: 1rem;
    @include flexbox;
    @include flex-direction(column);
    border-top: 0.5px solid var(--color-border);
  }
  .tableContent {
    margin-bottom: auto;
    overflow: auto !important;
  }
  .tableRow {
    @include tableRow;
  }
  .textCellHeader {
    @include textCell;
    @include flex(0 0 26.66%);
    @include flexbox;
    @include flex-direction(column);
    &:first-child {
      @include flex(0 0 5%);
    }
    &:nth-child(2) {
      @include flex(0 0 15%);
    }
    &:not(:last-child) {
      border-right: 0.5px solid var(--color-border);
    }
  }
  .textCell {
    @include textCell;
    @include flex(0 0 26.66%);
    &:first-child {
      @include flex(0 0 5%);
      font-weight: bold;
    }
    &:nth-child(2) {
      @include flex(0 0 15%);
    }
    &:not(:last-child) {
      border-right: 0.5px solid var(--color-border);
    }
  }
  .typeText {
    margin-left: 0.5rem;
  }
  .toolTip {
    @include toolTip;
  }
  .genericLabel {
    @include genericLabel;
  }
  .hisSkyTooltip:hover .toolTip {
    visibility: visible;
    color: var(--color-primary-blue);
  }
  .hisSkyTooltip:hover .toolTip {
    visibility: visible;
    color: var(--color-primary-blue);
  }
  .titleTerminal {
    font-size: 1.4rem;
    font-weight: 600;
    color: black;
    margin: 1rem 0;
  }
  .notextCell {
    text-overflow: ellipsis !important;
    @include flex(0 0 100%);
    text-transform: capitalize;
    padding-left: 0.5rem;
    @include globalInfoMsg;
  }
  .statusIconGreen {
    color: var(--color-terminal-online);
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    font-size: 1rem;
  }

  .statusIconRed {
    color: red;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    font-size: 1rem;
  }
}
