@import "../../../index.scss";

.ViewWindow {
  @include flexbox;
   @include flex-direction(column);
  @include justify-content(flex-start);
  @include align-items(center);

  font-family: "Poppins, sans-serif";
  min-height: 14rem;
  min-width: 17rem;
  padding: 0 2rem;
  .img {
    width: 7rem;
    margin-bottom: 1rem;
  }
  .title {
    font-family: "Poppins", sans-serif !important;
    font-size: 1.2rem;

    font-weight: 400;
    @include respond(tab-port-big) {
      font-size: 1.2rem;
    }
    // text-transform: capitalize;
  }
  .icon {
    color: #fff;
    border-radius: 6px;
    outline: none;
    border: none;
    margin-top: 1rem;
    text-transform: capitalize;
    font-size: 1.2rem;
    padding: 0.7rem 1rem;
    margin-left: 1rem;
    width: 5rem;
    position: relative;
    z-index: 1000;
    background-color: #2196f3;
    cursor: pointer;
    text-align: center;
  }
  .toolTip {
    @include toolTip;
  }
  .icon:hover .toolTip {
    visibility: visible;
    color: var(--color-primary-blue);
  }
  .btnWrapper {
    display: flex;
    justify-content: space-around;
  }
  .btn {
    color: #fff;
    border-radius: 6px;
    outline: none;
    border: none;
    margin-top: 1rem;
    text-transform: capitalize;
    font-size: 1.2rem;
    padding: 0.7rem 1rem;
    margin-left: 1rem;
    width: 5rem;
    position: relative;
    z-index: 1000;
    cursor: pointer;
    background-color: #2196f3;
    &:hover {
      box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2),
        0px 3px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14);
    }
  }
  
}
