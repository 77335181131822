@import "../../../../index.scss";

.UploadHistorys {
  background-color: #fff;
  @include positionCenter;
  width: 80%;
  height: 90vh;
  border-radius: 1rem;
  position: relative;
  .info {
    padding: 2rem 2rem;
    height: 90vh;
    @include flexbox;
    @include flex-direction(column);
  }
  .header {
    @include modelHeader;
  }
  .historyTitleIcon {
    font-size: 1.5rem;
    margin-right: 1rem;
    color: var(--color-primary-blue);
    cursor: pointer;
  }
  .breakLine {
    @include modelBreakLine;
  }
  .spinnerWrapper {
    @include positionCenter;
  }
  .form {
    @include flex-wrap(wrap);
    @include flexbox;
    padding: 0rem 2rem;
    background-color: #fff;
    @include justify-content(space-between);
    margin-bottom: auto;
    height: 80%;
  }
  .tableWrapper {
    width: 100%;
    height: 100%;
    @include flexbox;
    @include flex-direction(column) //padding: 0 2rem;
;
  }
  .table {
    margin-top: 1rem;
    @include flexbox;
    @include flex-direction(column);
    overflow: auto;
    height: 100%;   
    border-top: 0.5px solid var(--color-border);
  }
  .tableContent {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 78%;
    height: 78%;
    position: relative;
  }
  .tableRow {
    @include tableRow;
    &:nth-child(odd):hover {
      background-color: var(--color-border);
    }
    &:nth-child(even):hover {
      background-color: #f2f2f1;
    }
    @include respond(tab-port-big) {
      font-size: 1.2rem;
    }
  }
  .textCellHeader {
    @include textCell;
    @include flex(0 0 19%);
    @include flexbox;
    @include flex-direction(column);
    &:first-child {
      @include flex(0 0 5%);
    }
    &:not(:last-child) {
      border-right: 0.5px solid var(--color-border);
    }
  }
  .textCell {
    @include textCell;
    @include flex(0 0 15.33%);
    @include flexbox;
    @include flex-direction(column);
    &:first-child {
      @include flex(0 0 5%);
    }
    &:nth-child(2) {
      @include flex(0 0 18.33%);
    }
    &:nth-child(7) {
      @include flex(0 0 10%);
    }
    &:nth-child(4) {
      @include flex(0 0 12%);
    }
    &:nth-child(5) {
      @include flex(0 0 12%);
    }
    &:nth-child(6) {
      @include flex(0 0 12%);
    }
    &:not(:last-child) {
      border-right: 0.5px solid var(--color-border);
    }
  }

  .pagination {
    @include pagination;
    margin-right: unset;
    margin-top: 2rem;
  }
  .btnWrapper {
    @include flexbox;
    @include justify-content(flex-end);
    margin-right: 2rem;
    margin-top: auto;
  }
  .download {
    color: var(--color-primary-blue);
    position: relative;
  }

  .toolTip {
    @include toolTip;
  }

  .createdBy:hover .toolTip {
    visibility: visible;
    color: var(--color-primary-blue);
    left: 10rem;
  }
  .date:hover .toolTip {
    visibility: visible;
    color: var(--color-primary-blue);
  }
  .assignTo:hover .toolTip {
    visibility: visible;
    color: var(--color-primary-blue);
  }
}
