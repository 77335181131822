@import "../../../index.scss";
.ShortcutMenus {
  min-width: 20rem;
  width: auto;
  border: solid 1px var(--color-border);
  border-radius: 0.6rem;
  box-shadow: var(--shadow-light);
  padding: 1rem;
  position: absolute;
  background-color: #fff;
  @include flexbox;
  @include flex-direction(column);
  @include align-items(flex-start);
  z-index: 2 !important;
  white-space: nowrap;
  min-height: 5rem;
  .item {
    width: 94%;
    font-size: 1.4rem;
    padding: 1rem;
    cursor: pointer;
    border: 1px solid var(--color-border);
    margin: 0.5rem;
    z-index: 2 !important;
    color:#000;
  
  }

  .titleWrapper {
    position: relative;
  }
  .title {
    font-size: 1.6rem;
    color: black;
    @include align-items(center);
    margin-left: 0.5rem;
  }
  .beamTitle {
    font-size: 1.6rem;
    color: black;
    text-align: center;
    width: 100%;
  }
  .itemWrapper {
    @include flexbox;
  }
  .subItemWrapper {
    @include flexbox;
     @include flex-direction(row);;
    position: relative;
  }
  .textWrapper {
    @include flexbox;
    @include flex-direction(column);
    margin-left: 2rem;
  }
  .titleIconWrapper {
    @include flexbox;
   @include  justify-content(space-between);
    width: 100%;
  }
  .imgSat {
    width: 2rem;
    height: 2rem;
    margin-right: 0.3rem;
    position: relative;
    top: 0.4rem;
  }
  .terminalIcon {
    font-size: 1.6rem;
    color: var(--color-primary-blue);
    margin-right: 0.5rem;
  }
  .rightIcon {
    font-size: 1.6rem;
    color: var(--color-primary-blue);
  }
  .titleIconWrapper{
    @include flexbox;
    @include justify-content(space-between);
  }
  .subMenu {
    min-width: 20rem;
    width: auto;
    border: solid 1px var(--color-border);
    border-radius: 0.6rem;
    box-shadow: var(--shadow-light);
    padding: 1rem;
    position: absolute;
    background-color: #fff;
    @include flexbox;
    @include  flex-direction(column);
    @include align-items(flex-start);
    left: 16rem;
    top: -1rem;
    max-height: 30rem;
    overflow-y: auto;
    overflow-x: hidden;
    white-space: nowrap;
  }
  .disabled {
    cursor: not-allowed !important;
    color: var(--color-disable) !important;
  }
  .iconClose {
    font-size: 1.6rem;
    cursor: pointer;
    color: #000;
  }
  .statusIcon {
    position: absolute;
    top: 35%;
    font-size: 1.1rem;
  }
  .textarea {
    width: 100%;
    height: 100%;
    padding: 1rem;
    overflow: auto;
    font-family: inherit;
    border: 1px solid var(--color-border);
    &:focus-within {
      border: 2px solid var(--color-border);
    }
  }
  .copyWrapper {
    @include flexbox;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
  }
  .iconsWrapper {
    @include flexbox;
    @include justify-content(space-between);
    width: 100%;
  }
  .copyIcon {
    font-size: 1.6rem;
    color: var(--color-primary-blue);
    cursor: pointer;
  }
  .copyMsg {
    font-size: 1.2rem;
    color: black;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: auto;
  }
  .spinnerWrapper {
    @include positionCenter;
  }
  .iconSat {
    width:2rem;
    height: 2rem;
    margin-right: 0.3rem;
    position: relative;
    top: 0.4rem;
  }
  .selectedItem{
    color: #fff;
    background-color: var(--color-primary-blue);
  }
}
