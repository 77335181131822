@import "../../index.scss";

.Spin {
  background-color: #fff;
  height: 100vh;
  .InfoWrapper {
    @include positionCenter;
    @include flexbox;
    @include flex-direction(column);
  }
  .imgWrapper {
    position: relative;
  }
  .img {
    width: 300px;
  }
  .loadingMsg {
    position: absolute;
    bottom: 4px;
    font-size: 17px;
    margin-left: 5px;
  }
}
