@import "../../index.scss";
.sitesMainWrapper {
  @include flexbox;
  @include flex-direction(column);
  width: 36rem;
  background: white;
  margin-left: 1rem;
  padding: 2rem;
  transition: max-height 0.25s ease-in;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  border-radius: 2px;
  position: relative;
}
.sitesListWrapper {
  overflow: auto;
  @include flexbox;
  @include flex-direction(column);
  padding-right: 2rem;
  background: white;
}
.site {
  font-size: 1.4rem;
  padding: 1rem 0;
  border-bottom: 1px solid #e7e7e7;
  @include flexbox;
  @include justify-content(flex-start);
  text-transform: capitalize;
  cursor: pointer;
  @include flex-direction(column);
  &:not(:first-child) {
    margin-top: 0.5rem;
  }
  &:last-child {
    border-bottom: none;
  }
}
.closeIcon {
  @include flexbox;
  @include justify-content(flex-end);
  font-size: 1.4rem;
  margin: 0.5rem;
  padding: 0.5rem;
  cursor: pointer;
}
.siteDeviceCount {
  font-size: 1.2rem;
  margin-left: 0.5rem;
  padding: 0 0.3rem;
}
.infoWrapper {
  @include flexbox;
  z-index: 100;
  padding-top: 0.5rem;
  outline: none;
  border: none;
  border-radius: 2px;
  color: #666666;
  font-size: 1rem;
  font-weight: 650;
  width: auto;
  line-height: 2.4rem;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  background-color: white;
}
.itemWrapper {
  position: relative;
  margin-right: 0.5rem;
  cursor: pointer;
  &:last-child {
    margin-left: auto;
  }
}
.countWrapper {
  @include flexbox;
  margin-left: auto;
  font-size: 1.2rem;
  cursor: default !important;
}
.iconClear {
  margin-left: 0.3rem;
  color: var(--color-primary-blue);
  cursor: pointer;
}
.statusIconGreen {
  color: var(--color-terminal-online);
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  font-size: 1rem;
}

.statusIconRed {
  color: red;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  font-size: 1rem;
}
.slantLine {
  border-bottom: 1px solid black;
  transform: translateY(12px) translateX(5px) rotate(150deg);
  position: absolute;
  display: inline-block;
  width: 3.4rem;
  z-index: 100;
}
.spinnerWrapper {
  @include positionCenter;
}
