@import "../../../index.scss";
.leftWrapperDiv {
  @include flexbox;
  @include flex-direction(column);
  @include flex(0 0 100%);
  position: relative;
}
.topRow {
  @include flexbox;
}
.infoWrapper {
  @include flexbox;
  z-index: 100;
  padding-top: 0.5rem;
  outline: none;
  border: none;
  border-radius: .2rem;
  color: #666666;
  font-size: 1rem;
  font-weight: 650;
  width: auto;
  line-height: 2.4rem;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  background-color: white;
}
.itemWrapper {
  position: relative;
  margin-right: 0.5rem;
  &:last-child {
    margin-left: 3rem;
  }
}
.sitesInfoWrapper {
  position: relative;
}
.itemWrapper:hover {
  cursor: pointer;
}
.hbsIcon {
  color: var(--color-primary-blue);
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.statusIconGreen {
  color: var(--color-terminal-online);
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.statusIconSites{
  color: #00bdd6;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.verticalLine {
  color: #000; 
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.statusIconRed {
  color: red;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.statusIconGray {
  color: #bdbdbd;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.statusHubs {
  color: #00bdd6;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.slantLine {
  border-bottom: 1px solid black;
  -webkit-transform: translateY(12px) translateX(5px) rotate(150deg);
  -ms-transform: translateY(12px) translateX(5px) rotate(150deg);
      transform: translateY(12px) translateX(5px) rotate(150deg);
  position: absolute;
  display: inline-block;
  width: 3.4rem;
  z-index: 100;
}
.iconClear {
  margin-left: 0.3rem;
  color: var(--color-primary-blue);
}
