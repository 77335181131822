@import "../../../index.scss";

.ViewWindow {
  @include flexbox;
  @include flex-direction(column);
  @include justify-content(flex-start);
  @include align-items(center);
  font-family: "Poppins, sans-serif";
  min-height: 10rem;
  min-width: 17rem;
  padding: 0 2rem;
  .img {
    width: 7rem;
    margin-bottom: 1rem;
  }
  .title {
    font-family: "Poppins", sans-serif !important;
    font-size: 1.4rem;
    font-weight: 500;
    @include respond(tab-port-big) {
      font-size: 1.2rem;
    }
  }
  .infoText {
    font-family: "Poppins", sans-serif !important;
    font-size: 1.2rem;
    font-weight: 400;
    @include respond(tab-port-big) {
      font-size: 1rem;
    }
  }
}
