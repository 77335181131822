@import "../../index.scss";

.btn {
  text-align: center;
  color: #fff;
  border-radius: 0.6rem;
  outline: none;
  border: none;
  font-weight: 500;
  text-transform: capitalize;
  font-size: 1.4rem;
  padding: 1rem 2rem;
  line-height: 2.5rem;
  letter-spacing: 1.25px;
  background-color: var(--color-button);
  @include respond(tab-port-big) {
    height: 100%;
    font-size: 1.3rem;
  }
  @include respond(medium-height-model) {
    height: 100%;
  }
  cursor: pointer;
  text-transform: capitalize;

  &:hover {
    @include hover;
  }
  &:disabled {
    @include disabled;
    cursor: not-allowed;
  }
  &:active {
    @include active;
  }
}
