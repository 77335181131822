@import "../../../index.scss";
.custommarker {
  position: absolute;
  margin-top:-14px;
  margin-left:-14px;
  // color: #7F6666;
  padding: 5px;
  border-radius: 6px;
  // background-color: #fff;
}
.selected {
  animation: blinker 1s linear infinite;
  font-size: 1.2rem;
}
@keyframes blinker {  
  50% { opacity: 0; }
}
.starIcon{
  color: gold;
     font-size: 1.2rme;
     margin-left: auto;
 }
