@import "../../index.scss";
.RefreshCaches {
  background-color: #fff;
  @include positionCenter;
  width: 50rem;
  height: 25rem;
  border-radius: 1rem;
  padding: 3rem;
}
.wrapper {
  @include flexbox;
  @include flex-direction(column);
  @include align-items(center);
  @include justify-content(center);
}
.title {
  font-size: 2rem;
  color: var(--color-input-font);
  padding: 1rem 1rem 0 1rem;
}
.subTitle {
  font-size: 1.2rem;
  margin-bottom: 0.8rem;
  color: var(--color-input-font);
}
.imgPlaceholder {
  width: 30%;
  margin-left: 17rem;
  margin-right: auto;
}
.btnOk {
  border-radius: 0.6rem;
  padding: 1.5rem 2rem;
  background-color: var(--color-button);
  border: none;
  outline: none;
  color: #fff;
  cursor: pointer;
  font-size: 1.4rem;
  text-transform: capitalize;

  &:hover {
    @include hover;
  }
  &:disabled {
    @include disabled;
  }
  &:active {
    @include active;
  }
}
.btnWrapper {
  margin: auto;
}
