.infoWrapper {
    position: absolute;
    z-index: 900;
    outline: none;
    border: none;
    border-radius: 2px;
    color: #666666;
    font-size: 1.2rem;
    font-weight: 650;
    width: auto;
    line-height: 2rem;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    cursor: pointer;
    margin-left: 1rem;
    margin-top: 1rem;
    padding: 1rem 0rem;
    text-align: left;
    padding: 1rem 1rem;
    // padding-right: 1rem;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    background: none rgb(255, 255, 255);
    white-space: nowrap;
  }