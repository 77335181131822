@import "../../index.scss";
.input {
  padding: 0.375rem 0.75rem;
  font-weight: 400 !important;
  line-height: 3 !important ;
  width: 100% !important;
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem !important;
  color: var(--color-input-font) !important;
  background-clip: padding-box !important;
  border-radius: 2.5px !important;
  border: 1px solid var(--color-input-border) !important;
  height: unset !important;
  border-radius: 0.25rem;
  &:not(:disabled) {
    background-color: #fff;
  }
  &:disabled {
    background-color: #e2e2e2 !important;
    cursor: not-allowed;
  }
  &::-webkit-input-placeholder {
    text-transform: capitalize;
    font-size: 1.2rem;
  }
  &::-webkit-datetime-edit {
    color: var(--color-input-font);
    text-transform: uppercase;
    cursor: pointer;
    font-family: "Poppins", sans-serif;
  }
  @include respond(tab-port-big) {
    line-height: 2.5 !important;
  }
}
.label {
  font-size: var(--input-label-font-size);
  text-transform: capitalize;
  padding-bottom: 0.8rem !important;
}
.regularLabel {
  font-size: var(--input-label-font-size);
  padding-bottom: .8rem;
}
.error {
  @include globalErrorMsg;
  margin-top: 0.8em;
  margin-bottom: 0.8rem;
}
.spinnerWrapper {
  font-size: 1.5rem;
  color: var(--color-primary-blue);
  position: relative;
  margin-left: 1rem;
}
