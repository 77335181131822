@import "../../index.scss";

.Layouts {
@include flexbox;
  position: relative;
  z-index: 1  !important;
}
main {
  @include flexbox;
  @include flex-direction(column);
  width: 100%;
  background-color:var(--color-background-layout)
}

.DashBoard {
  position: relative;
  z-index: 1  !important;

}
.SpinnerWrapper {
  @include positionCenter;
}
