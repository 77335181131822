@import "../../../../index.scss";
.ViewTerminals {
  @include positionCenter;
  width: 60rem;
  border-radius: 1rem;
  background-color: #fff;
  padding: 2rem 2rem;
  min-height: 30rem;
  @include flexbox;
  max-height: 90vh;
   @include flex-direction(column);
  .header {
    @include modelHeader;
  }
  .breakLine {
    @include modelBreakLine;
    margin: 0 2rem;
  }
  .dataWrapper {
    max-height: 80%;
    overflow: auto;
  }
  .form {
    @include flexbox;
    padding: 1rem 2rem;
    @include flex-wrap(wrap);
    justify-content: space-between;
  }
  .formApi {
    @include flexbox;
    padding: 0rem 2rem;
    @include flex-wrap(wrap);
    justify-content: space-between;
  }
  .spinnerWrapper {
    @include positionCenter;
  }
  .inputWrapper {
    @include flex(0 0 45%);
    @include inputWrapper;
    margin: 1rem 1rem 1rem 0rem;
    &:first-child {
      margin-top: 0rem;
    }
    &:nth-child(2) {
      margin-top: 0rem;
    }
  }
  .btnWrapper {
    @include flexbox;
    @include justify-content(flex-end);
    margin-right: 1rem;
    margin-top: 1.5rem;
  }
  .spinnerWrapper {
    @include positionCenter;
  }

  .labelText {
    margin-left: 1rem;
    font-family: inherit;
    font-size: 1.6rem;
    color: inherit;
  }
  .error {
    margin-left: 1rem;
    margin-top: 0.3rem;
    font-family: inherit;
    @include globalErrorMsg;
  }
  .label {
    font-size: 1.2rem;
    padding-left: 1rem;
  }
  .destinationTitle {
    font-size: 1.4rem;
    color: black;
    margin: 0 2rem;
    text-transform: capitalize;
  }
  .destinationTitleApi {
    font-size: 1.4rem;
    color: black;
    text-transform: capitalize;
  }
  .iconNet {
    color: var(--color-primary-blue);
    margin-right: 0.5rem;
  }
  .destinationBreakLine {
    @include modelBreakLine;
    margin: 1rem 2rem; 
    position: relative;
  }
  .destinationBreakLineApi{
    @include modelBreakLine;
    margin: 1rem 0rem; 
    position: relative;
  }
}
