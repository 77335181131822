@import "../../index.scss";
.mapWrapper {
  position: "relative !important";
  height: "100%";
  z-index: 10 !important;
}

.infomodels {
  position: relative;
}
.backdrop {
  transform: translate(-50%, -50%);
  width: 100%;
  height: 121%;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
}
.terminalInfo {
  @include flexbox;
  @include flex-direction(column);
  @include flex(0 0 100%);
  position: relative;
  margin-bottom: 3rem;
  margin-left: -6.8rem;
}
.closebtnWrapper {
  @include flexbox;
  @include flex-direction(column);
  @include flex(0 0 100%);
  position: relative;
  margin-bottom: 3rem;
  margin-right: -8rem;
}
.zoomControls {
  @include flexbox;
  right: 8px !important;
  bottom: 25px !important;
}

.actionLeft {
  height: 100%;
}
.beamRightMenu {
  position: relative;
  @include flexbox;
  @include flex-direction(column);
  width: 100%;
  min-width: 30rem;
  overflow: hidden;
}
.polygonInfo{
  z-index: 10000000 !important;
}