@import "../../../index.scss";
.LinkExpands {
  @include flexbox;
  @include flex-direction(column);
  border-radius: 0.6rem;
  margin-left: 2.5%;
  width: 95%;
  padding: 0.4rem 0;

  .linkWrapper {
    font-size: 1.6rem;
    color: #fff;
    margin-bottom: 1rem;
    width: 95%;
    margin-left: 2.5%;
    padding: 1rem 0;
    border-radius: 0.6rem;
    &:hover {
      background-color: #0aa1b5;
    }
  }
  .link {
    padding: 0.4rem 0;
    text-decoration: none;
    text-transform: capitalize;
    color: #fff !important;
    font-size: var(--sidebar-label-font-size);
    margin-left: 0.5rem;
    width: 100%;
    display: inline-block;
  }
  .mainTitleClose {
    text-align: center !important ;
    color: #fff;
    margin-bottom: 4rem;
    cursor: pointer;
  }
  .linkClose {
    text-decoration: none;
    text-transform: capitalize;
    color: #fff !important;
    font-size: 2rem;
    cursor: pointer;
  }
  .mainTitle {
    font-size: var(--sidebar-label-font-size);
    color: #fff;
    margin-bottom: 1rem;
    width: 100%;
    margin-left: 0.5rem;
    cursor: pointer;
    border-radius: 0.6rem;
    @include flexbox;
    @include align-items(center);
    &:last-child {
      margin-left: auto;
    }
  }
  .mainTitle:hover .iconExpand {
    font-size: 1.8rem;
  }
  .textLink {
    margin-right: 1.7rem;
    text-transform: capitalize;
    margin-right: auto;
  }
  .iconLink {
    margin-right: 1.5rem;
    display: inline-block;
    margin-left: 1.7rem;
    cursor: pointer;
    font-size: var(--sidebar-icon-font-size);
    &:last-child {
      margin-left: auto;
    }
  }
  .iconExpand {
    display: inline-block;
    margin-left: 1.7rem;
    cursor: pointer;
    font-size: 1.6rem;
    font-weight: 300;
    margin-left: auto;
    margin-right: 1rem;

    &:hover {
      font-size: 1.8rem;
    }
  }
  .subMenu {
    text-decoration: none;
    margin-left: 16%;
    font-size: 1.1rem;
    margin-top: -0.5rem;
    @include slideTransition;
  }
  .open {
    @include slideTransitionOpen;
  }
  .subRouteLink {
    font-size: 1.1rem;
    border-radius: 6px;
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
    &:hover {
      background-color: #0aa1b5;
    }
  }
  .linkWrapperClose {
    text-align: center !important ;
    @include transition(all 0.5s);
    margin-bottom: 4rem;
    color: #fff;
  }
  .iconClose {
    font-size: 2rem;
  }
  .icon {
    cursor: pointer;
    font-size: 1.6rem;
    font-weight: 300;
    margin-right: 2rem;
  }
  .icon:hover {
    color: #0aa1b5;
  }
}
