@import "./index.scss";

.Apps {
  margin: auto;
  background-color: #fff;


  @include respond(tab-port) {
    margin: auto;
  }
}

.content {
  display: flex;
  @include respond(tab-port) {
    flex-direction: column;
    display: flex;
  }
}
.spinnerWrapper {
  @include positionCenter;
}
